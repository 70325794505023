<template>
  <span>
    <b
      v-if="show == 'both' || show == 'icon'"
      :class="{ disabled: $attrs.disabled }"
      :title="title"
    >
      <i :class="icon.class" />
    </b>
    <span v-if="show == 'both' || show == 'label'"> {{ title }} </span>
  </span>
</template>

<script>
export default {
  name: "IconConnection",
  props: {
    state: {
      type: Object,
      required: false,
      default: () => ({
        id: 0,
        number: 0,
        name: "Unknown"
      })
    },
    show: {
      type: String,
      required: false,
      default: "icon"
    }
  },
  computed: {
    icon() {
      return {
        "1": {
          // connected
          class: "fa fa-link text-success",
          title: this.$t("connected")
        },
        "2": {
          // disconnected
          class: "fa fa-unlink text-danger",
          title: this.$t("disconnected")
        },
        "3": {
          // invalid data configuration
          class: "fa fa-exclamation-triangle text-warning",
          title: this.$t("invalid_data")
        },
        "4": {
          // expired contract
          class: "fa fa-exclamation-triangle text-danger",
          title: this.$t("account_locked.contract_requires_attention")
        },
        "5": {
          // connected plug
          class: "fa fa-link text-success",
          title: this.$t("connected")
        },
        "0": {
          // unknown
          class: "fa fa-question text-warning",
          title: this.$t("unknown")
        }
      }[this?.state?.number ?? 0];
    },
    title() {
      return this?.state?.name || this.icon.title || "unknown";
    }
  }
}
</script>

<style scoped>
b {
  margin: 0 5px;
}
b.disabled {
  opacity: 0.6;
}
</style>